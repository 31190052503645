import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Le jour où j’ai testé Sass",
  "description": "Retour d’expérience sur ma première expérience approfondie de Sass.",
  "date": "2012-10-15T00:00:00.000Z",
  "path": "/articles/le-jour-ou-j-ai-teste-sass/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`J’ai lu récemment pas mal de débats sur l’intérêt d’intégrer ou non les préprocesseurs CSS dans nos workflows. Certains pour, d’autres contre, bref, les avis semblaient vraiment partagés sur le sujet. D’après ce que j’avais pu en lire, ils pouvaient pourtant présenter un réel intérêt dans certains cas, notamment sur de gros projets. Curieux d’en savoir un peu plus sur le sujet, j’ai récemment plongé mon nez dans Sass, qui semblait le plus populaire des différents préprocesseurs.`}</p>
    <h2>{`Sass en quelques mots`}</h2>
    <p><a parentName="p" {...{
        "href": "http://sass-lang.com/"
      }}>{`Sass`}</a>{` est, dixit son site officiel, une extension de CSS3 qui permet d’introduire de nouveaux concepts au sein de CSS : variables, imbrication d’éléments, héritage de sélecteurs etc. La syntaxe utilisée est le SCSS (pour Sassy CSS), qui permet de créer des fichiers .scss facilement convertibles en fichiers .css au moyen de l’outil de commande en ligne ou par le biais d’apps.`}</p>
    <h2>{`Comment l’installer ?`}</h2>
    <p>{`Le site officiel préconise d’utiliser Ruby (installé par défaut sous Mac, à `}<a parentName="p" {...{
        "href": "http://rubyinstaller.org/downloads/"
      }}>{`installer`}</a>{` sous Windows). On installe ensuite Sass avec la commande `}<code parentName="p" {...{
        "className": "text"
      }}>{`gem install sass`}</code>{`. On crée un fichier SCSS, par exemple `}<code parentName="p" {...{
        "className": "text"
      }}>{`style.scss`}</code>{` et on le "surveille" avec `}<code parentName="p" {...{
        "className": "text"
      }}>{`sass --watch style.scss:style.css`}</code>{`.`}</p>
    <p>{`Ou sinon, pour les paresseux (dont je fais partie) on installe un logiciel qui fait automatiquement le boulot; en voici une petite liste :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://incident57.com/codekit/"
        }}>{`Codekit`}</a>{` (Mac, payant)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://alphapixels.com/prepros/"
        }}>{`Prepros`}</a>{` (Windows, gratuit)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://compass.handlino.com/"
        }}>{`Compass.app`}</a>{` (Windows, payant)`}</li>
    </ul>
    <h2>{`Les avantages de Sass`}</h2>
    <p>{`La page d’accueil de Sass en dénombre 4. Il suffit en effet de les mettre en pratique pour rapidement comprendre la puissance de l’outil, et son énorme potentiel lors de la réalisation de projets complexes… Regardons ça un peu plus en détails :`}</p>
    <h3>{`Nesting`}</h3>
    <p>{`Le nesting, qu’on pourrait plus ou moins traduire par imbrication, permet de regrouper ensemble plusieurs sélecteurs en les imbriquant pour plus de clarté. Par exemple, prenons le SCSS suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`ul `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`a `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Il se traduira en CSS par :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`ul`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`ul a`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Le nesting est donc un bon moyen d’organiser clairement ses informations. Par ailleurs, il marche non seulement avec les sélecteurs mais également avec leurs propriétés.`}</p>
    <h3>{`Variables`}</h3>
    <p>{`Sass permet d’intégrer la notion de variables en CSS, variables qui peuvent être couplées avec certaines opérations mathématiques ou fonctions : on peut ainsi définir une variable `}<code parentName="p" {...{
        "className": "text"
      }}>{`$couleur`}</code>{`, qui correspondra par exemple à la couleur principale de notre site, puis styler certains éléments à partir de celle-ci en les rendant légèrement plus clairs : `}<code parentName="p" {...{
        "className": "text"
      }}>{`lighten($couleur, 10%)`}</code>{`. Il suffit ensuite de changer la valeur de notre variable `}<code parentName="p" {...{
        "className": "text"
      }}>{`$couleur`}</code>{` pour qu’automatiquement les autres propriétés dépendant de celle-ci se mettent à jour.`}</p>
    <h3>{`Les @mixins`}</h3>
    <p>{`Ils permettent de réutiliser une partie de notre CSS n’importe où grâce à la fonction `}<code parentName="p" {...{
        "className": "text"
      }}>{`@include`}</code>{`. Ainsi le SCSS suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`@mixin`}</span>{` `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`list-padd `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`ul `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`li `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`nav `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@include`}</span>{` list-padd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Sera traduit en CSS par :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`nav ul`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`nav li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Simple non ? :)`}</p>
    <h3>{`Héritage de sélecteurs`}</h3>
    <p>{`Fonctionnant un peu sur le même principe que les `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixins`}</code>{`, la fonction `}<code parentName="p" {...{
        "className": "text"
      }}>{`@extend`}</code>{` permet d’étendre les styles d’un sélecteur à un autre. Par exemple, le SCSS suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.red `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` red`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.button `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@extend`}</span>{` .red`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`donnera ce CSS :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.red,
.button`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` red`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.button`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Une fonction pratique donc, qui permet d’éviter d’avoir à copier/coller 20 fois le même code au moindre changement.`}</p>
    <h2>{`En conclusion…`}</h2>
    <p>{`… L’essayer, c’est l’adopter :) Plus sérieusement, après quelques temps d’utilisation, Sass m’apparaît comme un outil indispensable lors de la réalisation de projets complexes. Les outils qu’il intègre permettent à mon sens de rationaliser et optimiser très fortement notre workflow. Il présente en revanche peut-être moins d’intérêt sur des projets plus petits, quoique…`}</p>
    <p>{`Par ailleurs, je n’ai cité qu’une partie de ses possibilités, pour plus d’informations sur son potentiel je vous invite à consulter son très riche `}<a parentName="p" {...{
        "href": "http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html"
      }}>{`fichier de référence`}</a>{`.`}</p>
    <p>{`Pour terminer sur les préprocesseurs, sachez qu’il en existe d’autres, et notamment `}<a parentName="p" {...{
        "href": "http://lesscss.org/"
      }}>{`LESS`}</a>{`, que je n’ai pas eu l’occasion de tester. `}<a parentName="p" {...{
        "href": "http://chriscoyier.net/"
      }}>{`Chris Coyier`}</a>{` a d’ailleurs rédigé un `}<a parentName="p" {...{
        "href": "http://css-tricks.com/sass-vs-less/"
      }}>{`comparatif sur le sujet`}</a>{`, désignant plus ou moins Sass comme la version gagnante par rapport à LESS…`}</p>
    <p>{`Enfin, si vous voulez peser le pour et le contre de l’utilisation des préprocesseurs, je vous invite à lire ces deux articles : `}<a parentName="p" {...{
        "href": "http://blog.kaelig.fr/post/24877648508/preprocesseurs-css-renoncer-par-choix-ou-par"
      }}>{`Préprocesseurs CSS : renoncer par choix, ou par ignorance ?`}</a>{`, et `}<a parentName="p" {...{
        "href": "http://www.amberweinberg.com/why-im-still-against-sass-less/"
      }}>{`Why I’m still against Sass/LESS`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      